import KeenSlider from 'keen-slider'
import 'keen-slider/keen-slider.min.css'


export const initFactsMarquee = () => {
  var animation = { duration: 24000, easing: (t) => t }
  var slider = new KeenSlider("#RS-marquee-facts", {
    slides: {
      perView: 3,
      spacing: 64
    },
    loop: true,
    renderMode: "performance",
    drag: true,
    breakpoints: {
      '(max-width: 768px)': {
        slides: {
          perView: 4,
          spacing: 32
        }
      },
    }
  })

  // Custom arrow controls
  const prevArrow = document.getElementById('RS-marquee-facts-nav--prev');
  const nextArrow = document.getElementById('RS-marquee-facts-nav--next');

  prevArrow.addEventListener('click', () => {
    slider.prev();
  });

  nextArrow.addEventListener('click', () => {
    slider.next();
  });

};

export const initPersonasMarquee = () => {
  var animation = { duration: 24000, easing: (t) => t }
  var slider = new KeenSlider("#RS-marquee-personas", {
    slides: {
      perView: 3,
      spacing: 64
    },
    loop: true,
    renderMode: "performance",
    drag: true,
    breakpoints: {
      '(max-width: 768px)': {
        slides: {
          perView: 4,
          spacing: 32
        }
      },
    }
  })

  // Custom arrow controls
  const prevArrow = document.getElementById('RS-marquee-personas-nav--prev');
  const nextArrow = document.getElementById('RS-marquee-personas-nav--next');

  prevArrow.addEventListener('click', () => {
    slider.prev();
  });

  nextArrow.addEventListener('click', () => {
    slider.next();
  });

};



export const initReportContentMarquee = () => {
  // var animation = { duration: 24000, easing: (t) => t }
  // var slider = new KeenSlider("#RS-marquee-report-content", {
  //   slides: {
  //     perView: 1,
  //     spacing: 64
  //   },
  //   loop: true,
  //   renderMode: "performance",
  //   drag: true,
  //   breakpoints: {
  //     '(max-width: 768px)': {
  //       slides: {
  //         perView: 1,
  //         spacing: 32
  //       }
  //     },
  //   }
  // })

  // const prevArrow = document.getElementById('RS-marquee-report-content-nav--prev');
  // const nextArrow = document.getElementById('RS-marquee-report-content-nav--next');

  // prevArrow.addEventListener('click', () => {
  //   slider.prev();
  // });

  // nextArrow.addEventListener('click', () => {
  //   slider.next();
  // });

};