export const initTolstoy = (pageName) => {
  document.addEventListener('scroll', () => {
    setTimeout(() => {
      if(pageName === 'Home'){
        window.tolstoyWidgetId='ifrlvsqqsvxl8'; 
      }
      var s1 = document.createElement('script');
      s1.type = 'text/javascript';
      s1.text = 'tolstoyAppKey="ddffdc6f-f027-49e5-9a13-e19a2880ab01";';
      document.head.appendChild(s1);
      var s2 = document.createElement('script');
      s2.type = 'text/javascript';
      s2.async = true;
      s2.src = 'https://widget.gotolstoy.com/widget/widget.js';
      document.head.appendChild(s2);
    }, 500)
  }, {once : true});
};


